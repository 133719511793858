import db from '../db'
import router from '../../router/index'
const key = `account`
export default {
    namespaced: true,
    state: {
        /** 用户ID */
        UserID: 0,
        /** 用户类型 */
        UserType: 0,
        /** 真实姓名 */
        RealName: '',
        // 记录退出登录之前的页面路
        preLoginPagePath: ''
    },
    actions: {
        /**
         * @description 退出
         */
        logout({ commit }) {
            // 清楚令牌信息
            commit('clear')
            // 清除账户信息
            // commit('instante/account/clear', null, { root: true })

            // 跳转到登录页面
            router.push({
                name: 'login',
                // query: {
                //   redirect: router.currentRoute.fullPath,
                // },
            })
        },
    },
    mutations: {
        /**
         * 从本地存储中加载令牌
         */
        loadfromlocalstorage(state) {
            const user = db.get(key)
            if (user) {
                state.UserID = user.UserID
                state.UserType = user.UserType
                state.RealName = user.RealName
            }
        },
        /**
        * 设置登陆后跳转的页面
        */
        preLoginPageRoute(state, path) {
            state.preLoginPagePath = path;
        },
        /**
         * 初始化用户信息
         */
        init(state, user) {
            if (!user) {
                user = db.get(key)
            }
            if (user) {
                state.UserID = user.UserID
                state.UserType = user.UserType
                state.RealName = user.RealName
            }
            
            db.set(key, user)
            console.log(user)
        },
        /**
         * 清楚用户信息
         */
        clear(state) {
            state.UserID = 0
            state.UserType = 0
            state.RealName = ''
            db.remove(key)
        },
    },
}