<template>
  <header class="head_box">
    <div class="head_box_content">
      <div class="left">
        <div
          class="img_box"
          style="
            width: 2rem;
            height: 0.4rem;
            cursor: pointer;
            display: none;
            justify-content: center;
            align-items: center;

          "
          @click="goHome"
        >
          <img src="static/img/TitleLog.png" alt />
        </div>
        <router-link
          :class="`ebook ${modulsName == 'ebook' ? 'active' : ''}`"
          :to="ebookPath"
          >{{ $t("ebookPage.serieslist.TR") }}</router-link
        >
        <router-link
          :class="`select ${modulsName == 'byPara' ? 'active' : ''}`"
          :to="selectParasPath"
          >{{ $t("selectPage.selparas.TR") }}</router-link
        >
        <router-link
          :class="`simuid ${modulsName == 'bySimuD' ? 'active' : ''}`"
          :to="selectSimuD"
          >{{ $t("selectPage.selSimuD.TR") }}</router-link
        >
        <slot></slot>
      </div>
      <div class="right" style="display:none;">
        <el-dropdown trigger="click" v-if="true">
          <span class="el-dropdown-link">
            {{ $t("header.lang.TR") }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click.native="toggleLang('cn')"
                :disabled="$i18n.locale == 'cn'"
                >{{ $t("header.cn.TR") }}</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="toggleLang('en')"
                :disabled="$i18n.locale == 'en'"
                >{{ $t("header.en.TR") }}</el-dropdown-item
              >
              <!-- <el-dropdown-item
              @click.native="toggleLang('es')"
              :disabled="$i18n.locale == 'es'"
              >{{ $t("header.es.TR") }}</el-dropdown-item
            >-->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown trigger="click" v-if="true">
          <span class="el-dropdown-link">
            {{ $t("header.Applications.TR") }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click="toggleApplications('danji')"
                icon="el-icon-download"
                >{{ $t("header.danji.TR") }}</el-dropdown-item
              >
              <el-dropdown-item
                @click="toggleApplications('android')"
                class="iconfont iconanzhuo"
                >{{ $t("header.android.TR") }}</el-dropdown-item
              >
              <el-dropdown-item
                @click="toggleApplications('ios')"
                class="iconfont iconios"
                >{{ $t("header.ios.TR") }}</el-dropdown-item
              >
              <el-dropdown-item
                @click="toggleApplications('wechat')"
                class="iconfont iconweixin"
                >{{ $t("header.wechat.TR") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link el-icon-user-solid">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="userinfo">
                {{ $t("header.userInfo.TR") }}
              </el-dropdown-item>
              <el-dropdown-item command="logout">
                {{ $t("header.logout.TR") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- <router-link to="/">首页</router-link> -->
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      ebookPath: "",
      selectParasPath: "",
      selectSimuD: "",
    };
  },
  computed: {
    modulsName: function () {
      //console.log(this.$route.meta, 110);
      return this.$route.meta.modulsName;
    },
  },
  mounted() {
    this.ebookPath = `/${this.$getCurrentLanguageUrl()}/EBook/SeriesList`;
    this.selectParasPath = `/${this.$getCurrentLanguageUrl()}/Select/ByPara/SelParas`;
    this.selectSimuD = `/${this.$getCurrentLanguageUrl()}/Select/BySimuD/SelParas`;
  },
  methods: {
    //点击图片返回首页
    goHome() {
      this.$router.push({
        path: `/${this.$getCurrentLanguageUrl()}/EBook/SeriesList`,
      });
    },
    handleCommand(cmd) {
      //console.log(cmd, 163);
      switch (cmd) {
        case "logout":
          this.$confirm("您确定要退出系统吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$store.dispatch("instante/account/logout");
            })
            .catch(() => {});
          break;
      }
    },
    toggleLang(lang) {
      if (lang == "cn") {
        this.$i18n.locale = "cn";
        this.$message({
          message: "切换为中文！",
          type: "success",
        });
        this.$router.push({
          path: "/CN/EBook/SeriesList",
        });
      } else if (lang == "en") {
        this.$i18n.locale = "en";
        this.$message({
          message: "Switch to English!",
          type: "success",
        });
        console.log(this.$getCurrentLanguageUrl(), 157);
        this.$router.push({
          path: "/EN/EBook/SeriesList",
        });
      }
      // console.log(lang,this.$i18n.locale,173)
    },
    toggleApplications(type) {
      console.log(type);
    },
  },
};
</script>

<style lang="scss">
.head_box {
  .head_box_content {
    width: 12rem;
    margin: auto;
    height: 0.46rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 0.43rem;
    padding: 0 0.1rem;
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 0.46rem;
    .img_box {
      width: 2rem;
      height: 0.4rem;
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }
  .right {
    display: flex;
    height: 0.46rem;
    justify-content: flex-end;
    align-items: center;
  }
  .el-dropdown {
    color: #333;
    font-size: 0.14rem;
    padding-right: 0.2rem;
    cursor: pointer;
  }
  a {
    color: #333;
    font-weight: bold;
    padding: 0 0.2rem;
    font-size: 0.16rem !important;
    text-decoration: none;
    border-bottom:3px solid transparent;
    margin-right: 0.1rem;
    &.active {
      // background-color: #dd9646;
      background-color: #F3F4F8;
      border-color: #1492ff;
      color: #1492ff;
    }
    &:hover {
      background-color: #F3F4F8;
      border-color: #1492ff;
      color: #1492ff;
    }
  }
}
</style>