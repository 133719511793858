import Vue from 'vue';
import Vuex from 'vuex';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import store from './store';
import axios from 'axios'
import VueCookies from 'vue-cookies'; //cookies
import '@/assets/css/iconfont/iconfont.css';
import './assets/css/skin.css';
import LanguageHelper from '@/lang/LangugageHelper'
// 引用v-viewer 查看图片的控件
import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import * as echarts from 'echarts';
import App from './views/index.vue';
import '@/utils/dialogDrag'; //element el-dialog 自定义拖动指令  v-dialogDrag
import '@/utils/modifyHtmlTitle'; //自定义修改网页title指令  v-myTitle
//  import "@/assets/styles/common.less";

let i18n = LanguageHelper.i18n


document.title = '高效水力模型'
Vue.prototype.$comHead = comHead;
//注册状态
// Vue.use(store);
//注册路由
Vue.use(router)
//注册cookie
Vue.use(VueCookies);
//注册国际化
Vue.use(i18n);
 axios.defaults.withCredentials=true;
Vue.prototype.$axios = axios
Vue.prototype.$globalConfig = window.globalConfig
Vue.prototype.$getCorpName = function () {
  return LanguageHelper.getCorpName()
}
Vue.prototype.$getCurrentLanguageType = function () {
  return LanguageHelper.getCurrentType();
}
Vue.prototype.$initI18nType = function () {
  return LanguageHelper.initI18nType()
}
Vue.prototype.$getCurrentLanguageUrl = function () {
  // var local = localStorage.getItem('locale') || 'cn';
  return LanguageHelper.getCurrentUrl();
}
Vue.prototype.$echarts = echarts
//注册图片预览 v-viewer
Vue.use(VueViewer)
// viewer的相关相关配置 具体参考 https://github.com/mirari/v-viewer  中文文档 https://mirari.cc/2017/08/27/Vue%E5%9B%BE%E7%89%87%E6%B5%8F%E8%A7%88%E7%BB%84%E4%BB%B6v-viewer%EF%BC%8C%E6%94%AF%E6%8C%81%E6%97%8B%E8%BD%AC%E3%80%81%E7%BC%A9%E6%94%BE%E3%80%81%E7%BF%BB%E8%BD%AC%E7%AD%89%E6%93%8D%E4%BD%9C/
VueViewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
Vue.use(store)
//从本地存储中加载用户信息
store.commit('instante/account/loadfromlocalstorage')

Vue.config.productionTip = false

Vue.use(ElementUI);
//注册多语言
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
new Vue({
  el: '#app',
  router,
  Vuex,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
