<template>
  <footer class="footer_box">
    <div class="footer_box_content">
      <div class="left">
        <a href="http://www.eventech.cn" target="_blank">{{
          $t("footer.technical.TR")
        }}</a>
        <div style="width: 20px"></div>
        <a :href="RecordNoUrl" target="_blank">{{ RecordNo }}</a>
        <slot></slot>
      </div>
      <div class="right">
        <div style="width: 20px"></div>
        数据仅供参考：如有修改恕不通知 版权所有，盗版必究
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      RecordNo: "", //备案号
      RecordNoUrl: "", //备案号地址
    };
  },
  mounted() {
    this.RecordNo = this.$globalConfig.CorpInfo.RecordNo;
    this.RecordNoUrl = this.$globalConfig.CorpInfo.RecordNoUrl;
  },
};
</script>

<style lang="scss">
.footer_box {
  .footer_box_content {
    width:1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    line-height: 40px;
  }

  a,
  div {
    font-size: 10px;
    color: #fff;
  }
  a {
    text-decoration: none;
  }
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img_box {
      width: 200px;
      height: 40px;
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>